"use client";

import { useState } from "react";
import { Search } from "react-iconly";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { Input } from "@/components/ui/input";
import { useDebounce } from "@/hooks/useDebounce";

export default function SearchBar() {
  const searchParams = useSearchParams();
  const [value, setValue] = useState(searchParams.get("q") ?? "");
  const pathname = usePathname();
  const router = useRouter();
  const debouncValue = useDebounce<string>((dv) => {
    const params = new URLSearchParams();
    params.set("q", dv);
    router.replace(pathname + `?${params.toString()}`);
  }, 500);

  if (pathname !== "/search")
    return (
      <Link
        href="/search"
        className="flex gap-2 items-center bg-[#FBF9F8] rounded-md flex-1 px-4 py-2 h-9"
      >
        <Search primaryColor="gray" size={16} />
        <span className="text-gray-500 text-xs">Нэрээр хайх...</span>
      </Link>
    );

  return (
    <div className="flex gap-2 items-center bg-[#FBF9F8] border border-white rounded-md flex-1 px-4 py-2 h-9 has-[:focus-visible]:border-primary has-[:focus-visible]:border">
      <Search primaryColor="gray" size={16} />
      <Input
        className="placeholder:text-gray-500 placeholder:text-xs bg-transparent border-none shadow-none outline-none !ring-0 !ring-offset-0 rounded-none p-0 h-full"
        placeholder="Нэрээр хайх..."
        value={value}
        onChange={(e) => {
          const v = e.target.value;
          setValue(v);
          debouncValue(v);
        }}
        autoFocus
      />
    </div>
  );
}
