"use client";

import { useEffect, useState } from "react";
import { ArrowLeft, InfoSquare, Lock, Logout, Paper } from "react-iconly";
import { BookOpen, Search, ShoppingCart } from "lucide-react";
import { usePathname } from "next/navigation";

import Dots from "@/assets/icons/dots.svg";
import { Link } from "@/components/custom";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useAuth, useCart } from "@/context";

export default function MobileHeader() {
  const pathname = usePathname();
  const [pageTitle, setPageTitle] = useState("");
  const { items } = useCart();
  const { logout } = useAuth();

  useEffect(() => {
    const updateTitle = () => {
      const newTitle = document.title;
      setPageTitle(newTitle);
    };

    updateTitle();

    const observer = new MutationObserver(updateTitle);
    if (typeof window !== "undefined" && !!document.querySelector("title"))
      observer.observe(document.querySelector("title")!, { childList: true });

    return () => observer.disconnect();
  }, [pathname]);

  const renderHeader = () => {
    if (pathname === "/" || pathname.startsWith("/community")) {
      return (
        <div className="container flex justify-between items-center w-full py-2">
          <h1 className="text-2xl font-bold flex-1 truncate min-w-0">
            {pageTitle || "Гоодаль"}
          </h1>
          <div className="flex items-center gap-2">
            <Link
              href="/search"
              className="bg-[#FBF9F8] w-10 h-10 flex items-center justify-center rounded-full"
            >
              <Search className="h-4 w-4" />
            </Link>
            <Link
              href="/checkout"
              className="bg-[#FBF9F8] w-10 h-10 flex items-center justify-center rounded-full relative"
            >
              <ShoppingCart className="h-4 w-4" />
              {!!items.length && (
                <span className="absolute rounded-full bg-primary -right-1 -top-1 text-xs text-white px-1 font-bold min-w-4 h-4 leading-none flex items-center justify-center">
                  {items.length}
                </span>
              )}
            </Link>
          </div>
        </div>
      );
    }

    if (pathname === "/account") {
      return (
        <div className="container flex justify-between items-center w-full py-2">
          <h1 className="text-2xl font-bold">Би</h1>
          <Drawer>
            <DrawerTrigger>
              <button className="bg-[#FBF9F8] w-10 h-10 flex items-center justify-center rounded-full">
                <Dots className="h-4 w-4" />
              </button>
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Тохиргоо</DrawerTitle>
              </DrawerHeader>
              <nav aria-label="Profile sidebar menu">
                <ul className="space-y-4">
                  <li>
                    <Link
                      href="/magazine/purchased"
                      className="px-4 py-3 gap-4 flex items-center hover:bg-[#FBF9F8] rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      activeClassName="text-primary bg-[#FBF9F8]"
                    >
                      <BookOpen aria-hidden="true" size={20} />
                      <span>Худалдаж авсан сэтгүүл</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/account/pin"
                      className="px-4 py-3 gap-4 flex items-center hover:bg-[#FBF9F8] rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      activeClassName="text-primary bg-[#FBF9F8]"
                    >
                      <Lock
                        set="light"
                        primaryColor="currentColor"
                        aria-hidden="true"
                        size={20}
                      />
                      <span>Пин код солих</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/account/faq"
                      className="px-4 py-3 gap-4 flex items-center hover:bg-[#FBF9F8] rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      activeClassName="text-primary bg-[#FBF9F8]"
                    >
                      <InfoSquare
                        set="light"
                        primaryColor="currentColor"
                        aria-hidden="true"
                        size={20}
                      />
                      <span>Нийтлэг асуулт хариулт</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/account/terms"
                      className="px-4 py-3 gap-4 flex items-center hover:bg-[#FBF9F8] rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      activeClassName="text-primary bg-[#FBF9F8]"
                    >
                      <Paper
                        set="light"
                        primaryColor="currentColor"
                        aria-hidden="true"
                        size={20}
                      />
                      <span>Үйлчилгээний нөхцөл</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="w-full text-left px-4 py-3 gap-4 flex items-center hover:bg-[#FBF9F8] text-primary rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      aria-label="Гарах"
                      onClick={logout}
                    >
                      <Logout
                        set="light"
                        primaryColor="currentColor"
                        aria-hidden="true"
                        size={20}
                      />
                      <span>Гарах</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </DrawerContent>
          </Drawer>
        </div>
      );
    }

    return (
      <div className="container flex items-center w-full py-2 gap-4 h-[56px]">
        <button onClick={() => window.history.back()}>
          <ArrowLeft size={20} set="light" />
        </button>
        <h1 className="text-lg font-semibold flex-1 min-w-0 truncate">
          {pageTitle}
        </h1>
      </div>
    );
  };

  return (
    <>
      <header
        className="fixed top-0 left-0 right-0 bg-background border-b z-50 md:hidden"
        suppressHydrationWarning
      >
        {renderHeader()}
      </header>
      <div style={{ height: 56 }} className="md:hidden" />
    </>
  );
}
