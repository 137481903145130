'use client';

import { useCallback,useEffect, useState } from 'react';

export function useDebounce<T>(callback: (v: T) => void, delay: number) {
  const [debouncedCallback, setDebouncedCallback] = useState<NodeJS.Timeout | null>(null);

  const debouncedFunction = useCallback((args: T) => {
    if (debouncedCallback) {
      clearTimeout(debouncedCallback as NodeJS.Timeout);
    }

    const newTimeout = setTimeout(() => {
      callback(args);
    }, delay);

    setDebouncedCallback(newTimeout);
  }, [callback, delay]);

  useEffect(() => {
    return () => {
      if (debouncedCallback) {
        clearTimeout(debouncedCallback as NodeJS.Timeout);
      }
    };
  }, [debouncedCallback]);

  return debouncedFunction;
}
